<template>
    <div class="app-container" v-loading="loading">
        <el-header class="new-header">
            <div class="new-header-left">商家报表</div>
        </el-header>
        <el-form :inline="true" :model="searchForm" ref="searchForm" @keyup.enter.native="onSubmit">
            <el-form-item label="商家">
                <el-select
                        v-model="searchForm.merchantId"
                        placeholder="请选择商家"
                        size="small"
                        @change="onSubmit"
                        filterable
                >
                    <el-option v-for="item in merchantList" :key="item.merchantId" :label="item.merchantName"
                               :value="item.merchantId"></el-option>

                </el-select>
            </el-form-item>
            <el-form-item label="报表类型">
                <el-select
                        v-model="searchForm.dateType"
                        placeholder="报表类型"
                        size="small"
                        @change="onSubmit"
                >
                    <el-option label="日报" :value="1"></el-option>
                    <el-option label="月报" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="时间:" prop="seatAccountName">
                <el-date-picker
                        v-model="dateRange"
                        placeholder="时间"
                        size="small"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        @change="handleDateChange"
                ></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit" icon="search" size="small"
                >搜索
                </el-button
                >
            </el-form-item>
        </el-form>
        <el-table
                :data="list"
                border
                style="width: 100%"
                ref="list"
                stripe
                size="small"
        >
            <el-table-column prop="dateTime" label="日期" align="center"></el-table-column>
            <el-table-column prop="numberOfConnection" label="接通次数" align="center"></el-table-column>
            <el-table-column prop="numberOfCall" label="呼出次数" align="center"></el-table-column>
            <el-table-column prop="effectiveNumberOfCall" label="有效呼出次数" align="center"></el-table-column>
            <el-table-column prop="percentConnectionRate" label="接通率" align="center"></el-table-column>
            <el-table-column prop="percentEffectiveConnectionRate" label="有效接通率" align="center"></el-table-column>
            <!--            <el-table-column prop="totalBillminStr" label="呼出计费时长" align="center"></el-table-column>-->
            <el-table-column prop="totalBillminStr" label="呼出通话时长" align="center"></el-table-column>
        </el-table>
        <div class="pagination-block">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageVo.current"
                    :page-sizes="[2, 10, 20, 50, 100]"
                    :page-size="pageVo.size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalCount"
            >
            </el-pagination>
        </div>
    </div>
</template>
<script>
    import {getMerchantNoPage, getSeatMerchantReportListOfAdmin} from "../../api";
    import moment from 'moment'

    export default {
        name: "",
        data() {
            return {
                userId: this.$store.getters.userId,
                dateRange: [],
                searchForm: {
                    adminId: this.$store.getters.adminId,
                    merchantId: null,
                    dateType: 1,
                    startTime: null,
                    endTime: null,
                    seatAccountName: null,
                },
                list: [],
                merchantList:[],
                pageVo: {
                    current: 1,
                    size: 10,
                },
                totalCount: 0,
                loading: false,
            };
        },
        created() {
            this.loadAllMerchantList();
        },
        methods: {
            async loadAllMerchantList() {
                this.merchantList = await getMerchantNoPage({adminId: this.$store.getters.adminId})
                if(this.merchantList.length){
                    this.searchForm.merchantId = this.merchantList[0].merchantId
                    await this.loadReportList()
                }
            },
            handleDateChange(val) {
                if (val) {
                    this.searchForm.startTime = val[0]
                    this.searchForm.endTime = val[1]
                } else {
                    this.searchForm.startTime = ''
                    this.searchForm.endTime = ''
                }
            },
            onSubmit() {
                this.pageVo.current = 1;
                this.loadReportList();
            },
            handleSizeChange(val) {
                this.pageVo.size = val;
                this.loadReportList();
            },
            handleCurrentChange(val) {
                this.pageVo.current = val;
                this.loadReportList();
            },
            loadReportList() {
                if (!this.searchForm.startTime && !this.searchForm.endTime) {
                    //日报
                    if (this.searchForm.dateType === 1) {

                        this.dateRange = [moment().add(-7, 'd').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
                        this.searchForm.startTime = moment().add(-7, 'd').format('YYYY-MM-DD')
                        this.searchForm.endTime = moment().format('YYYY-MM-DD')
                    }
                    //月报
                    if (this.searchForm.dateType === 2) {
                        this.dateRange = [moment().add(-1, 'M').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
                        this.searchForm.startTime = moment().add(-1, 'M').format('YYYY-MM-DD')
                        this.searchForm.endTime = moment().format('YYYY-MM-DD')
                    }
                }
                let params = {
                    ...this.pageVo,
                    params: this.searchForm,
                };

                this.loading = true;
                getSeatMerchantReportListOfAdmin(params).then((data) => {
                    this.list = data.records;
                    this.totalCount = data.total;
                    this.loading = false;
                });
            },
        },
    };
</script>
<style lang="scss"></style>
